import type RecipeSchema from '../../recipes.schema'

type GetRecipeUrlIdProps = {
  recipe: RecipeSchema
}

export default function getRecipeUrlId({ recipe }: GetRecipeUrlIdProps) {
  const recipeIsDefined = !!recipe?.data

  if (!recipeIsDefined) {
    return ''
  }

  const recipeUrlIdSplit = recipe.data.title.split(' ')
  const recipeUrlIdMap = recipeUrlIdSplit.map((text) => text.toLocaleLowerCase())
  const recipeUrlId = recipeUrlIdMap.join('-')

  return recipeUrlId
}
