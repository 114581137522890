import React from 'react'
import Box from '@useweb/ui/Box'
import IconButton from '@mui/material/IconButton'

import IconHamburgerMenu from '../../../../../../lib/components/icons/IconHamburgerMenu'

type MenuProps = {
  name: string
}

export default function MenuUi({ name }: MenuProps) {
  return (
    <Wrapper>
      <MenuButton />
    </Wrapper>
  )
}

const Wrapper = ({ children }) => {
  return (
    <Box
      sx={{
        visibility: 'hidden',
      }}
    >
      {children}
    </Box>
  )
}

const MenuButton = () => {
  return (
    <Box
      sx={{
        mt: {
          xs: '10px',
        },
      }}
    >
      <IconButton>
        <IconHamburgerMenu />
      </IconButton>
    </Box>
  )
}
