import React from 'react'

import GoogleAdsHeadScript from '../../../lib/components/ads/googleAds/GoogleAdsHeadScript/GoogleAdsHeadScript'

export const clientCaPub = '9650447144755728'

export default function GoogleAds() {
  return (
    <>
      {process.env.NODE_ENV === 'production' && (
        <GoogleAdsHeadScript clientCaPub={clientCaPub} />
      )}
    </>
  )
}
