import React from 'react'
import dynamic from 'next/dynamic'

import { type AdUnitProps } from './AdUnit/AdUnit'

const AdUnit = dynamic(() => import('./AdUnit/AdUnit'), {
  ssr: false,
})

export type GoogleAdUnitProps = AdUnitProps

export default function GoogleAdUnit(props: AdUnitProps) {
  return <AdUnit {...props} />
}
