import React, { useState } from 'react'
import Box from '@useweb/ui/Box'
import IconButton from '@mui/material/IconButton'
import Text from '@useweb/ui/Text'
import Modal from '@useweb/ui/Modal'

import Link from '../../../../../../lib/components/Link/Link'
import IconSearch from '../../../../../../lib/components/icons/IconSearch'
import SearchBox from '../../../../../../lib/components/SearchBox/SearchBox'
import type RecipeSchema from '../../../../../../data/recipes/recipes.schema'
import getRecipeUrlId from '../../../../../../data/recipes/utils/getRecipeUrlId/getRecipeUrlId'

type SearchUiProps = {
  recipes: RecipeSchema[]
}

export default function SearchUi({ recipes }: SearchUiProps) {
  const [showSearchbox, setShowSearchbox] = useState(false)

  const closeModal = () => {
    setShowSearchbox(false)
  }

  return (
    <Wrapper>
      <SearchButton onClickOpen={() => setShowSearchbox((c) => !c)} />
      <SearchInput open={showSearchbox} onClose={closeModal} recipes={recipes} />
    </Wrapper>
  )
}

const Wrapper = ({ children }) => {
  return (
    <Box sx={{}} data-id='Search'>
      {children}
    </Box>
  )
}

const SearchButton = ({ onClickOpen }) => {
  return (
    <Box>
      <IconButton
        data-id='SearchButton'
        onClick={onClickOpen}
        sx={{
          transform: {
            lg: 'translateY(20px)',
          },
        }}
      >
        <IconSearch
          sx={{
            fontSize: '20px',
          }}
        />
      </IconButton>
    </Box>
  )
}

const SearchInput = ({ open, onClose, recipes }) => {
  const ListItemComponent = (itemData: RecipeSchema) => {
    const noData = !itemData?.data

    return (
      <Link
        href={`/recipes/${getRecipeUrlId({ recipe: itemData })}`}
        onClick={onClose}
        sx={{
          pointerEvents: noData ? 'none' : 'auto',
        }}
      >
        <Text
          text={noData ? 'No results found' : itemData?.data?.title}
          sx={{
            fontSize: {
              xs: '14px',
              lg: '20px',
            },
            '&:hover': {
              textDecoration: 'underline',
            },
          }}
        />
      </Link>
    )
  }

  return (
    <Box>
      <Modal
        open={open}
        onClose={onClose}
        sx={{
          top: {
            xs: '20px',
            lg: '80px',
          },
          width: {
            xs: '90%',
            lg: '40%',
          },
          '& input': {
            fontSize: {
              xs: '14px',
              lg: '20px',
            },
          },
        }}
      >
        <SearchBox
          data={recipes}
          ListItemComponent={ListItemComponent}
          resultsSx={{
            gap: '15px',
          }}
        />
      </Modal>
    </Box>
  )
}
