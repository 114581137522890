const colors: any = {
  primary: {
    main: '#5D59FF',
  },
  gradient: {
    primary: 'linear-gradient(83.6deg, #5D59FF 8.71%, #9076FF 98.37%)',
  },
  pink: {
    main: '#FF8B8B',
    light: '#FFDADA',
    lightest: '#FFF5F5',
    dark: '#590000',
    darkest: '#C50000',
  },
  gray: { main: '#8c8c8c' },
  black: {
    main: '#1C1929',
  },
  white: {
    main: '#fff',
  },
  error: {
    main: '#d93025',
  },
};

colors.themeColor = colors.pink.lightest;
colors.backgroundColor = colors.pink.lightest;

export default colors;
