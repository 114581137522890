import colors from '../../tokens/colors'

export default {
  MuiCssBaseline: {
    styleOverrides: `
          :root {
            --font-main: BentonSansRegular;
          }

          @font-face {
            font-family: 'AlikeRegular';
            font-style: normal;
            font-display: swap;
            src: url('/fonts/alike/regular.woff2') format('woff2');
          }     

          @font-face {
            font-family: 'BentonSansRegular';
            font-style: normal;
            font-display: swap;
            src: url('/fonts/bentonSans/regular.woff2') format('woff2');
          }       
       
          * {
            box-sizing: border-box;
          }

          html {
            overflow-x: hidden;
            scroll-behavior: auto !important;
          }
          
          body {
            -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
            box-sizing: border-box;
            margin: 0;
            background-color: ${colors.backgroundColor};
          }

          body::-webkit-scrollbar {
            width: 8px;
          }

          body {
            scrollbar-width: thin;
          }
        
          body::-webkit-scrollbar-thumb {
            background-color: #C1C1C1;
            border-radius: 10px;
          }

          p,
          h1,
          h2,
          h3,
          h4,
          h5,
          h6 {
            padding: 0;
            margin: 0;
          }

          a {
            text-decoration: none;
          }

          input[type=number]::-webkit-inner-spin-button,input[type=number]::-webkit-outer-spin-button {
            -webkit-appearance: none;
            margin: 0px;
          }

          .firebase-emulator-warning {
            display: none;
          }

          .visually-hidden {
            clip: rect(0 0 0 0);
            clip-path: inset(50%);
            height: 1px;
            overflow: hidden;
            position: absolute;
            white-space: nowrap;
            width: 1px;
          }

          input {
            border: none;
          }
        
          textarea:focus, input:focus{
            outline: none;
          }

        `,
  },
}
