import React from 'react'
import Box from '@useweb/ui/Box'

import Header from './Header/Header'
import Footer from './Footer/Footer'

export default function RootLayout({ children, recipes = [] }) {
  return (
    <>
      <Header recipes={recipes} />
      <Content>{children}</Content>
      <Footer />
    </>
  )
}

const Content = ({ children }) => {
  return (
    <Box
      component='main'
      sx={{
        maxWidth: '989.43px',
        display: 'block',
        margin: '0 auto',
      }}
    >
      {children}
    </Box>
  )
}
