import React from 'react'

import IconBakingTitle from '../../../../../lib/components/icons/IconBakingTitle'

import TitleUi from './TitleUi/Title.ui'

export default function Title() {
  const title = 'Hayle Tenjo'

  return <TitleUi title={title} Icon={IconBakingTitle} />
}
