import React from 'react'
import Script from 'next/script'

declare global {
  interface Window {
    clarity: any
  }
}

const isProduction = process.env.NODE_ENV === 'production'

export default function ClarityScript() {
  return isProduction ? (
    <>
      <Script
        id='clarity-script'
        strategy='afterInteractive'
        dangerouslySetInnerHTML={{
          __html: `(function(c,l,a,r,i,t,y){
            c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
            t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
            y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
        })(window, document, "clarity", "script", "hd3ud3km5v");`,
        }}
      />
    </>
  ) : null
}
