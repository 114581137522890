import * as prismic from '@prismicio/client'

const apiEndpoint = 'https://hayletenjo.prismic.io/api/v2'
const repositoryName = prismic.getRepositoryName(apiEndpoint)
const accessToken =
  'MC5ZanQtbkJFQUFDTUFPemd1.77-977-9H--_vWA4Ou-_ve-_ve-_vVYYHn3vv71t77-9YwXvv73vv71U77-9J--_ve-_ve-_ve-_ve-_vUMt77-9'

const prismicConfig = {
  repositoryName,
  apiEndpoint,
  accessToken,
  previewUrl: 'https://static.cdn.prismic.io/prismic.js?new=true&repo=hayletenjo',
}

export default prismicConfig
