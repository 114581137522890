import React from 'react'
import Box from '@useweb/ui/Box'
import Text from '@useweb/ui/Text'

import IconCupcake from '../../../../lib/components/icons/IconCupcake'
import Link from '../../../../lib/components/Link/Link'

export type FooterUiProps = {
  text?: string
}

export default function FooterUi({}: FooterUiProps) {
  return (
    <Wrapper>
      <IconCupcake />
      <Box
        sx={{
          display: 'grid',
          gridAutoFlow: 'column',
          alignItems: 'center',
          gap: '4px',
        }}
      >
        <Text tag='p' text={'Created by'} sx={textStyles} />
        <Link
          href='https://www.jeremytenjo.com/'
          newTab
          sx={{
            ...textStyles,
            fontWeight: 'bold',
            textDecoration: 'underline',
            '&:active': {
              color: 'pink.main',
            },
          }}
        >
          Jeremy Tenjo
        </Link>
        <Text tag='p' text={'All Rights Reserved.'} sx={textStyles} />
      </Box>
    </Wrapper>
  )
}

const textStyles = {
  color: 'pink.main',
  textAlign: 'center',
  fontSize: '13.07px',
  mb: '15px',
}

const Wrapper = ({ children }) => {
  return (
    <Box
      sx={{
        display: 'grid',
        justifyItems: 'center',
        gridGap: '10px',
        mt: {
          xs: '100px',
          lg: '300px',
        },
      }}
    >
      {children}
    </Box>
  )
}
