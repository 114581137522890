import React from 'react'
import { createSvgIcon } from '@mui/material'

export default createSvgIcon(
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g>
      <path
        d='M9.7788 19.6487C11.9437 19.6482 14.0463 18.9235 15.7517 17.5898L21.1135 22.9516L22.8382 21.227L17.4764 15.8651C18.8107 14.1596 19.5359 12.0565 19.5364 9.89101C19.5364 4.51089 15.1589 0.133362 9.7788 0.133362C4.39867 0.133362 0.0211487 4.51089 0.0211487 9.89101C0.0211487 15.2711 4.39867 19.6487 9.7788 19.6487ZM9.7788 2.57277C13.8148 2.57277 17.097 5.855 17.097 9.89101C17.097 13.927 13.8148 17.2092 9.7788 17.2092C5.74279 17.2092 2.46056 13.927 2.46056 9.89101C2.46056 5.855 5.74279 2.57277 9.7788 2.57277Z'
        fill='black'
      />
      <path
        d='M11.501 8.16635C11.9633 8.62983 12.2182 9.24213 12.2182 9.89101H14.6576C14.6587 9.24997 14.5327 8.61507 14.2869 8.02301C14.0411 7.43096 13.6804 6.89349 13.2257 6.44168C11.3791 4.59748 8.17732 4.59748 6.33191 6.44168L8.05413 8.16878C8.98111 7.24425 10.5789 7.24669 11.501 8.16635Z'
        fill='black'
      />
    </g>
    <defs>
      <clipPath id='clip0_523_33'>
        <rect
          width='23.581'
          height='23.581'
          fill='white'
          transform='translate(0.0211487 0.0316772)'
        />
      </clipPath>
    </defs>
  </svg>,
  'IconSearch',
)
