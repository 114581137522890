import React from 'react'
import { createSvgIcon } from '@mui/material'

export default createSvgIcon(
  <svg viewBox='0 0 35 13' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M0.13031 1.86841C0.13031 1.26044 0.623169 0.767578 1.23114 0.767578H33.4462C34.0541 0.767578 34.547 1.26044 34.547 1.86841V1.86841C34.547 2.47638 34.0541 2.96924 33.4462 2.96924H1.23114C0.623169 2.96924 0.13031 2.47638 0.13031 1.86841V1.86841Z'
      fill='#040404'
    />
    <path
      d='M0.13031 11.7759C0.13031 11.168 0.623169 10.6751 1.23114 10.6751H33.4462C34.0541 10.6751 34.547 11.168 34.547 11.7759V11.7759C34.547 12.3839 34.0541 12.8768 33.4462 12.8768H1.23114C0.623169 12.8768 0.13031 12.3839 0.13031 11.7759V11.7759Z'
      fill='#040404'
    />
  </svg>,
  'IconHamburgerMenu',
)
