import React, { useEffect, useMemo, useRef, useState } from 'react'
import Box, { type BoxProps } from '@useweb/ui/Box'
import Text from '@useweb/ui/Text'
import Form from '@useweb/ui/Form'
import Textfield from '@useweb/ui/Textfield'

import IconSearch from '../icons/IconSearch'
import List from '../List/List'

export type SearchBoxProps = {
  data: any[]
  ListItemComponent: any
  sx?: BoxProps['sx']
  resultsSx?: BoxProps['sx']
}

export default function SearchBox({
  data = [],
  ListItemComponent,
  sx = {},
  resultsSx = {},
}: SearchBoxProps) {
  const [value, setValue] = useState('')

  return (
    <Wrapper sx={sx}>
      <Input setValue={setValue} />
      <Results
        query={value}
        data={data}
        ListItemComponent={ListItemComponent}
        resultsSx={resultsSx}
      />
    </Wrapper>
  )
}

const Wrapper = ({ children, sx }) => {
  return (
    <Box
      data-id='SearchBox'
      sx={{
        p: '20px',
        display: 'grid',
        ...sx,
      }}
    >
      {children}
    </Box>
  )
}

const Input = ({ setValue }) => {
  const inputRef = useRef<any>(null)

  const clearInput = () => {
    setValue('')
    focusInput()
  }

  useEffect(() => {
    focusInput()
  }, [])

  const focusInput = () => {
    inputRef?.current && inputRef?.current.focus()
  }

  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: 'fit-content(100%) 1fr',
        gap: '15px',
        alignItems: 'center',
      }}
    >
      <IconSearch />

      <Form onSubmit={() => null}>
        <Textfield
          name='recipeQuery'
          placeholder='Search recipe'
          onChange={(query) => setValue(query)}
          inputProps={{
            autoFocus: true,
            autoComplete: 'off',
          }}
          sx={{
            fontSize: '16px',
            width: '100%',
            '& input': {
              fontFamily: 'Georgia',
            },
          }}
          clearComponent={
            <Text
              text='Clear'
              onClick={clearInput}
              sx={{
                fontFamily: 'BentonSansRegular',
                fontWeight: 'bold',
                textDecoration: 'underline',
                fontSize: {
                  xs: '12px',
                  lg: '14px',
                },
                cursor: 'pointer',
              }}
            />
          }
        />
      </Form>
    </Box>
  )
}

const Results = ({ query, data, ListItemComponent, resultsSx }) => {
  const filteredData = useMemo(() => {
    if (query === '') {
      return []
    }

    const filtered = data.filter((d) =>
      d.data.title.toLowerCase().includes(query.toLowerCase()),
    )

    if (filtered.length === 0) {
      return [false]
    }

    return filtered
  }, [query, data])

  return (
    <List
      data={filteredData}
      ListItemComponent={ListItemComponent}
      sx={{
        maxHeight: '200px',
        overflow: 'auto',
        mt: filteredData.length > 0 ? '25px' : '',
        ...resultsSx,
      }}
    />
  )
}
