import * as React from 'react'
import PropTypes from 'prop-types'
import Head from 'next/head'
import { CacheProvider } from '@emotion/react'

import ClickToComponent from '../lib/components/ClickToComponent/ClickToComponent'
import Theme from '../theme/theme'
import createEmotionCache from '../theme/mui/utils/createEmotionCache'
import RootLayout from '../content/_Root/Root.layout'
import GoogleAnalytics from '../services/googleAnalytics/GoogleAnalytics'
import Prismic from '../services/prismic/Prismic'
import GoogleAds from '../services/googleAds/GoogleAds/GoogleAds'
import ClarityScript from '../lib/integrations/Microsoft/Clarity/ClarityScript/ClarityScript'
// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache()

export default function MyApp(props) {
  const { Component, emotionCache = clientSideEmotionCache, pageProps } = props

  return (
    <>
      <ClickToComponent />
      <GoogleAnalytics />
      <GoogleAds />
      <ClarityScript />

      <Head>
        <meta
          name='description'
          content='How to Master the Art of Baking with Hayle Tenjo: Tips, Recipes, and Techniques for Perfectly Delicious Treats'
        />
        <meta name='viewport' content='initial-scale=1, width=device-width' />
        <meta name='author' content='Hayle Tenjo' />
        {/* vercel staging sites will always have no index */}
        <meta name='robots' content='index, follow' />
      </Head>

      <CacheProvider value={emotionCache}>
        <Theme>
          <Prismic>
            <RootLayout {...pageProps}>
              <Component {...pageProps} />
            </RootLayout>
          </Prismic>
        </Theme>
      </CacheProvider>
    </>
  )
}

MyApp.propTypes = {
  Component: PropTypes.elementType.isRequired,
  emotionCache: PropTypes.object,
  pageProps: PropTypes.object.isRequired,
}
