import React from 'react'

import GoogleAnalyticsScripts from '../../lib/utils/googleAnalytics/GoogleAnalyticsScripts/GoogleAnalyticsScripts'

import googleAnalyticsConfig from './googleAnalytics.config'

export default function GoogleAnalytics() {
  return process.env.NODE_ENV === 'production' ? (
    <>
      <GoogleAnalyticsScripts measurementId={googleAnalyticsConfig.measurementId} />
    </>
  ) : null
}
