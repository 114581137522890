import React, { Fragment } from 'react'
import Box, { type BoxProps } from '@useweb/ui/Box'
import useMediaQuery from '@useweb/ui/useMediaQuery'

import GoogleAdUnit from '../ads/googleAds/GoogleAdUnit/GoogleAdUnit'

export type ListProps = {
  data: any[]
  ListItemComponent: any
  enableAds?: boolean
  sx?: BoxProps['sx']
  onItemClick?: (data: { data: any }) => any
}

export default function List({
  data = [],
  ListItemComponent,
  enableAds = false,
  sx = {},
  onItemClick = () => null,
}: ListProps) {
  return (
    <Wrapper sx={sx as any}>
      <Items
        data={data}
        ListItemComponent={ListItemComponent}
        enableAds={enableAds}
        onItemClick={onItemClick}
      />
    </Wrapper>
  )
}

const Wrapper = ({ children, sx = {} }) => {
  return (
    <Box
      data-id='List'
      component='ul'
      sx={{ display: 'grid', margin: '0', padding: '0', listStyle: 'none', ...sx }}
    >
      {children}
    </Box>
  )
}

const Items = ({ data, ListItemComponent, enableAds, onItemClick }) => {
  const isXs = useMediaQuery({ type: 'only', size: 'xs' })
  const isSm = useMediaQuery({ type: 'only', size: 'sm' })
  const isLg = useMediaQuery({ type: 'only', size: 'lg' })

  let showAdRef = false
  let itemCountRef = 0
  // show ad every
  const splitNumber = isXs.matches ? 3 : isSm.matches ? 8 : isLg.matches ? 11 : 7

  return data.map((item, index) => {
    if (itemCountRef === splitNumber) {
      itemCountRef = 0
      showAdRef = true
    } else {
      itemCountRef = itemCountRef + 1
      showAdRef = false
    }

    return (
      <Fragment key={item.id + Math.random() + index}>
        <Box
          component='li'
          sx={{
            listStyle: 'none',
          }}
          onClick={() => onItemClick({ data: item })}
        >
          <ListItemComponent index={index} {...item} />
        </Box>

        {enableAds && showAdRef && (
          <Box
            component='li'
            sx={{
              minHeight: process.env.NODE_ENV === 'development' ? '200px' : 'auto',
              gridColumn: {
                xs: 'span 2',
                sm: 'span 3',
                md: 'span 4',
              },
            }}
          >
            <GoogleAdUnit
              dataAdFormat='fluid'
              dataAdSlot='6894435001'
              dataAdLayoutKey='-6s+ea+2i-1i-4k'
            />
          </Box>
        )}
      </Fragment>
    )
  })
}
