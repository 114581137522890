import React from 'react'
import Box from '@useweb/ui/Box'

import Menu from './Menu/Menu'
import Title from './Title/Title'
import Search from './Search/Search'

export default function HeaderUi({ recipes }) {
  return (
    <Wrapper>
      <InnerWrapper>
        <Menu />
        <Title />
        <Search recipes={recipes} />
      </InnerWrapper>
    </Wrapper>
  )
}

const Wrapper = ({ children }) => {
  return (
    <Box
      sx={{
        background: 'linear-gradient(180deg,#FFF5F5 0%, rgba(255, 245, 245, 0) 100%)',
        position: 'relative',
        zIndex: 3,
        mb: {
          xs: '26px',
          lg: '120px',
        },
        pt: {
          xs: '10px',
          lg: '40px',
        },
        px: {
          xs: '20px',
        },
      }}
    >
      {children}
    </Box>
  )
}

const InnerWrapper = ({ children }) => {
  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: 'fit-content(100%) 1fr fit-content(100%)',
        width: '100%',
        maxWidth: '990px',
        margin: '0 auto',
      }}
    >
      {children}
    </Box>
  )
}
