import React from 'react'
import Box from '@useweb/ui/Box'
import Text from '@useweb/ui/Text'

export type TitleProps = { title: string; Icon: any }

export default function TitleUi({ title, Icon }: TitleProps) {
  return (
    <Wrapper>
      <Text
        tag='h1'
        text={title}
        sx={{
          textTransform: 'uppercase',
          fontFamily: 'AlikeRegular',
          textAlign: 'center',
          fontSize: {
            xs: '24px',
            lg: '47.64px',
          },
          userSelect: 'none',
          color: 'black.main',
        }}
      />

      <Icon
        sx={{
          fontSize: { xs: '49px', lg: '89px' },
          display: 'block',
          margin: '0 auto',
          mt: { xs: '-28px', lg: '-55px' },
        }}
      />
    </Wrapper>
  )
}

const Wrapper = ({ children }) => {
  return (
    <Box
      component='a'
      href='/'
      sx={{
        display: 'block',
        margin: '0 auto',
        width: 'fit-content',
      }}
    >
      {children}
    </Box>
  )
}
