import React from 'react'

import MenuUi from './MenuUi/Menu.ui'

type MenuProps = {
  name: string
}

export default function Menu() {
  const name = 'prop'

  return <MenuUi name={name} />
}
